.header {
  @apply bg-primary-950 sticky top-0 z-50;
}

.header-navbar {
  @apply w-full h-16  mx-auto;
  @apply px-4 lg:px-0 lg:w-[80%];
}

.header-navbar-container {
  @apply flex justify-between items-center;
}

.header-container-section-right {
  @apply flex space-x-4 items-center justify-center;
}

.header-section-right__combo {
  @apply py-4 hidden lg:block;
}

.header-combo-container {
  @apply relative inline-block w-[80px] mr-[16px];
}

.header-combo__label {
  @apply cursor-pointer mb-1;
  @apply px-3 py-1 rounded-md text-center;
}

.header-combo__options {
  @apply bg-primary-950 text-primary-50 rounded-md;
  @apply w-[380px] absolute mt-1 -ml-[125px];
}

.header-combo__option {
  @apply cursor-pointer px-3 py-3 text-center text-[14px];
  @apply hover:bg-primary-500 hover:border hover:font-semibold;
}

.header-section-right__button {
  @apply bg-primary-50 font-light text-[16px] h-8 w-24 rounded-sm;
  @apply hover:bg-primary-600 hover:text-primary-50;
}

.menubar-container {
  @apply h-full w-1/2 bg-neutral-900;
  @apply lg:hidden fixed top-0 left-0 z-10;
  @apply transform transition-transform ease-in-out duration-300;
}

.menubar-ul {
  @apply flex flex-col space-y-4 p-4 items-center;
}

.menubar-ul-li {
  @apply w-full flex items-center ml-[20px];
}

.menubar-ul-li a {
  @apply text-primary-50 text-[16px] hover:text-neutral-400;
}
