.slider-container {
  @apply w-full h-[320px];
  @apply md:h-[464px] lg:h-[500px];
}

.slider-container .swiper-button-next {
  @apply right-[10%] text-white opacity-50;
  @apply hidden lg:block;
}

.slider-container .swiper-button-prev {
  @apply left-[10%] text-white opacity-50;
  @apply hidden lg:block;
}

.slider-container__info {
  @apply w-[95%] h-full mx-auto;
  @apply lg:w-[70%];
  @apply flex items-center text-primary-50;
}

.slider-info {
  @apply w-full h-full;
  @apply flex items-center relative;
}

.slider-info__image {
  @apply w-full h-full;
  @apply absolute flex items-center;
  @apply sm:justify-center;
}

.slider-info__left {
  @apply w-4/5 sm:w-3/5 h-full absolute;
  @apply flex flex-col justify-center;
}

.slider-left__title {
  @apply font-semibold text-[30px];
  @apply md:text-[42px] xl:text-[48px] leading-none;
}

.slider-left__description {
  @apply font-light text-[16px] lg:text-[18px] mt-4;
}

.slider-left__button {
  @apply h-[56px] px-8;
  @apply hover:bg-primary-50 text-primary-50;
  @apply hover:text-primary-500 rounded-md mt-[40px];
}

.swiper-pagination-bullet {
  @apply bg-black opacity-50;
}

.swiper-pagination-bullet-active {
  @apply bg-primary-50;
}

.swiper-pagination-circle {
  @apply relative hidden md:block;
}

@media only screen and (min-width: 768px) {
  .swiper-pagination-bullet {
    @apply w-[40px] h-[40px];
    @apply leading-[20px] text-[12px] text-primary-50;
    @apply bg-black opacity-20;
  }

  .swiper-pagination-circle svg {
    @apply absolute top-0 fill-none;
    @apply stroke-[3px] stroke-primary-50;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    @apply transform rotate-[-90deg];
  }

  .swiper-pagination-circle-index {
    @apply w-full h-full p-[9px];
    @apply flex items-center justify-center;
  }

  .swiper-pagination-bullet-active {
    @apply text-primary-50;
  }
}
