.container-benefits {
  @apply w-full py-10 mt-12;
}

.container-benefits__info {
  @apply w-[95%] lg:w-[80%] mx-auto;
}

.container-benefits__title {
  @apply font-semibold text-center text-[32px] mb-12 leading-none;
}

.container-benefits__items {
  @apply flex flex-col md:flex-row items-center;
}

.container-benefits-item {
  @apply w-full flex-1 flex flex-col items-center;
  @apply px-4 mb-4 md:mb-0;
}

.container-benefits-item__image {
  @apply w-[60px] h-[60px];
  @apply md:w-[120px] md:h-[120px];
}

.container-benefits-item__title {
  @apply font-semibold text-[18px] text-center mt-5;
}

.container-benefits-item__description {
  @apply text-[16px] text-justify px-16 pt-3;
}
