.swiper-slide1 {
  @apply relative;
  transition: transform 0.3s, box-shadow 0.3s; /* Transiciones para el efecto de hover */
}

.swiper-slide1:hover {
  transform: scale(1.01); /* Escala ligeramente el slide en hover */
  box-shadow: 10px rgb(25, 7, 118); /* Agrega sombra en hover */
  @apply cursor-pointer;
}

.slider-container-partners {
  @apply h-[360px] px-[10%];
}

.slider-container-partners .swiper-button-next {
  @apply text-primary-800;
  @apply bg-primary-50;
  @apply w-[5%] h-full -mt-[180px] right-0 absolute;
}

.slider-container-partners .swiper-button-prev {
  @apply text-primary-800;
  @apply bg-primary-50;
  @apply w-[5%] h-full -mt-[180px] left-0 absolute;
}
